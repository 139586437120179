import { create } from 'zustand';

import { ConfirmationModalParamsType } from './types';

type GlobalStoreType = {
	confirmationModalParams: ConfirmationModalParamsType | null;
};

type GlobalActions = {
	setConfirmationModalParams: (params: ConfirmationModalParamsType | null) => void;
	reset: () => void;
};

const initialState: GlobalStoreType = {
	confirmationModalParams: null,
};

const globalStore = create<GlobalStoreType & GlobalActions>()((set) => ({
	...initialState,
	setConfirmationModalParams: (confirmationModalParams) =>
		set((state) => {
			const newState = confirmationModalParams ? { open: true, ...confirmationModalParams } : null;
			return { ...state, confirmationModalParams: newState };
		}),
	reset: () => set(() => ({ ...initialState })),
}));

export const useGlobalStore = () => globalStore((state) => state);
