import { createIntl, createIntlCache } from 'react-intl';
import { enUS, it as fnsIT } from 'date-fns/locale';
import en from 'translations/compiled/en.json';
import it from 'translations/compiled/ita.json';

export enum LOCALES {
	EN = 'en',
	IT = 'it',
}

export const supportedLocales = {
	[LOCALES.EN]: {
		locale: LOCALES.EN,
		messages: en,
	},
	[LOCALES.IT]: {
		locale: LOCALES.IT,
		messages: it,
	},
};

const muiSupportedLocales = {
	[LOCALES.EN]: enUS,
	[LOCALES.IT]: fnsIT,
};

export const defaultLocale = LOCALES.EN;
export const muiDefaultLocale = muiSupportedLocales[defaultLocale];

const cache = createIntlCache();
const intl = createIntl(supportedLocales[defaultLocale], cache);

export default intl;
