/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EExitStatus = (typeof EExitStatus)[keyof typeof EExitStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EExitStatus = {
	PENDING: 'PENDING',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
} as const;
