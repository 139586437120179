import { FC } from 'react';
import { Button as MUIButton, CircularProgress } from '@mui/material';
import { ColorPalette } from 'shared/theme';

import { ButtonPropsType } from 'components/button/type-helpers';

const Button: FC<ButtonPropsType> = ({ variant = 'contained', loading = false, disabled, sx, ...props }) => {
	return (
		<MUIButton
			variant={variant}
			disabled={disabled || loading}
			sx={{
				...sx,
			}}
			{...props}
		>
			{loading ? <CircularProgress size="25px" sx={{ color: ColorPalette.black }} /> : props.children}
		</MUIButton>
	);
};

export default Button;
