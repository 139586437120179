/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GRApp API (staging)
 * GRApp API server
 * OpenAPI spec version: 0.0.1
 */

export type EExitLogStatus = (typeof EExitLogStatus)[keyof typeof EExitLogStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EExitLogStatus = {
	inside: 'inside',
	outside: 'outside',
	unauthorized: 'unauthorized',
} as const;
