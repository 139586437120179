import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppBar, Box, IconButton, styled, Toolbar, Typography } from '@mui/material';
import GR_logo_white from 'assets/images/GR_logo_white.png';
import { Routes } from 'routes/consts';
import { ColorPalette, theme } from 'shared/theme';
import { useAuthStore } from 'store/auth';

type SidebarPropsType = {
	open: boolean;
	toggleDrawer: (val: boolean) => void;
};

const Header: FC<SidebarPropsType> = ({ open, toggleDrawer }) => {
	const navigate = useNavigate();
	const { user } = useAuthStore();

	return (
		<AppBar sx={{ zIndex: theme.zIndex.drawer + 1, background: ColorPalette.lightOrange }}>
			<Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
					{open ? (
						<IconButton onClick={() => toggleDrawer(false)}>
							<ChevronLeftIcon color="primary" />
						</IconButton>
					) : (
						<IconButton onClick={() => toggleDrawer(true)}>
							<MenuIcon color="primary" />
						</IconButton>
					)}
					<img height="50px" src={GR_logo_white} style={{ cursor: 'pointer' }} onClick={() => navigate(Routes.root)} />
				</Box>

				<NavLinkStyled to={Routes.profile_settings}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Typography color={ColorPalette.black}>
							{user?.firstName} {user?.lastName}
						</Typography>
						<Typography color={ColorPalette.gray} variant="caption">
							{user?.email}
						</Typography>
					</Box>
					<MoreVertIcon color="primary" fontSize="large" />
				</NavLinkStyled>
			</Toolbar>
		</AppBar>
	);
};

export default Header;

const NavLinkStyled = styled(NavLink)`
	display: flex;
	align-items: center;
	gap: 5px;
	text-align: end;
	text-decoration: none;
	padding: ${theme.spacing(2)};
	padding-right: 0;
	border-radius: 5px;
	&.active {
		background-color: ${ColorPalette.beige};
	}
`;
