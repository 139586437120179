import { FC } from 'react';
import { Box, Tooltip, TooltipProps } from '@mui/material';

type InformationTooltipProps = TooltipProps;

const InformationTooltip: FC<InformationTooltipProps> = ({ title, children, sx, ...props }) => {
	return (
		<Tooltip title={title} arrow {...props}>
			<Box
				sx={{
					cursor: 'pointer',
					whiteSpace: 'nowrap',
					maxWidth: '300px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					display: 'inline-block',
					...sx,
				}}
			>
				{children}
			</Box>
		</Tooltip>
	);
};

export default InformationTooltip;
